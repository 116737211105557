.container {
    width: 100%
}
@media (min-width: 320px) {
    .container {
        max-width: 320px
    }
}
@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px
    }
}
.visible {
    visibility: visible
}
.fixed {
    position: fixed
}
.absolute {
    position: absolute
}
.relative {
    position: relative
}
.sticky {
    position: -webkit-sticky;
    position: sticky
}
.top-2\/4 {
    top: 50%
}
.left-2\/4 {
    left: 50%
}
.right-2 {
    right: 0.5rem
}
.top-1 {
    top: 0.25rem
}
.order-last {
    order: 9999
}
.order-first {
    order: -9999
}
.col-span-2 {
    grid-column: span 2 / span 2
}
.col-span-1 {
    grid-column: span 1 / span 1
}
.m-6 {
    margin: 1.5rem
}
.m-0 {
    margin: 0px
}
.m-8 {
    margin: 2rem
}
.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem
}
.mx-8 {
    margin-left: 2rem;
    margin-right: 2rem
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
}
.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
}
.my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem
}
.mx-auto {
    margin-left: auto;
    margin-right: auto
}
.my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem
}
.mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem
}
.my-0 {
    margin-top: 0px;
    margin-bottom: 0px
}
.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
}
.my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
}
.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}
.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem
}
.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
}
.mt-4 {
    margin-top: 1rem
}
.mb-8 {
    margin-bottom: 2rem
}
.mt-16 {
    margin-top: 4rem
}
.mr-3 {
    margin-right: 0.75rem
}
.mb-4 {
    margin-bottom: 1rem
}
.mt-auto {
    margin-top: auto
}
.mt-5 {
    margin-top: 1.25rem
}
.ml-2 {
    margin-left: 0.5rem
}
.mb-9 {
    margin-bottom: 2.25rem
}
.mr-1 {
    margin-right: 0.25rem
}
.ml-0 {
    margin-left: 0px
}
.mb-5 {
    margin-bottom: 1.25rem
}
.ml-auto {
    margin-left: auto
}
.mb-16 {
    margin-bottom: 4rem
}
.mb-6 {
    margin-bottom: 1.5rem
}
.mt-9 {
    margin-top: 2.25rem
}
.mb-3 {
    margin-bottom: 0.75rem
}
.mb-12 {
    margin-bottom: 3rem
}
.ml-4 {
    margin-left: 1rem
}
.mr-4 {
    margin-right: 1rem
}
.mt-2 {
    margin-top: 0.5rem
}
.mb-2 {
    margin-bottom: 0.5rem
}
.ml-6 {
    margin-left: 1.5rem
}
.mr-3\.5 {
    margin-right: 0.875rem
}
.ml-1 {
    margin-left: 0.25rem
}
.mt-3 {
    margin-top: 0.75rem
}
.mt-0 {
    margin-top: 0px
}
.mr-2 {
    margin-right: 0.5rem
}
.ml-3 {
    margin-left: 0.75rem
}
.mb-10 {
    margin-bottom: 2.5rem
}
.mt-7 {
    margin-top: 1.75rem
}
.mt-1 {
    margin-top: 0.25rem
}
.mt-1\.5 {
    margin-top: 0.375rem
}
.mt-0\.5 {
    margin-top: 0.125rem
}
.mr-10 {
    margin-right: 2.5rem
}
.mb-1\.5 {
    margin-bottom: 0.375rem
}
.mb-1 {
    margin-bottom: 0.25rem
}
.mt-8 {
    margin-top: 2rem
}
.block {
    display: block
}
.inline-block {
    display: inline-block
}
.inline {
    display: inline
}
.flex {
    display: flex
}
.inline-flex {
    display: inline-flex
}
.table {
    display: table
}
.grid {
    display: grid
}
.hidden {
    display: none
}
.h-8 {
    height: 2rem
}
.h-screen {
    height: 100vh
}
.h-auto {
    height: auto
}
.h-full {
    height: 100%
}
.h-3\.5 {
    height: 0.875rem
}
.h-3 {
    height: 0.75rem
}
.h-24 {
    height: 6rem
}
.h-7 {
    height: 1.75rem
}
.max-h-\[168px\] {
    max-height: 168px
}
.w-11\/12 {
    width: 91.666667%
}
.w-full {
    width: 100%
}
.w-10\/12 {
    width: 83.333333%
}
.w-4\/5 {
    width: 80%
}
.w-3\.5 {
    width: 0.875rem
}
.w-3 {
    width: 0.75rem
}
.w-40 {
    width: 10rem
}
.w-9\/12 {
    width: 75%
}
.w-14 {
    width: 3.5rem
}
.w-16 {
    width: 4rem
}
.max-w-screen-lg {
    max-width: 1024px
}
.max-w-\[224px\] {
    max-width: 224px
}
.flex-1 {
    flex: 1 1 0%
}
.flex-shrink {
    flex-shrink: 1
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.cursor-pointer {
    cursor: pointer
}
.cursor-default {
    cursor: default
}
.resize {
    resize: both
}
.list-none {
    list-style-type: none
}
.appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}
.flex-row {
    flex-direction: row
}
.flex-col {
    flex-direction: column
}
.flex-wrap {
    flex-wrap: wrap
}
.items-center {
    align-items: center
}
.justify-end {
    justify-content: flex-end
}
.justify-center {
    justify-content: center
}
.justify-between {
    justify-content: space-between
}
.gap-5 {
    gap: 1.25rem
}
.gap-4 {
    gap: 1rem
}
.gap-x-2 {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem
}
.justify-self-start {
    justify-self: start
}
.overflow-x-hidden {
    overflow-x: hidden
}
.overflow-x-scroll {
    overflow-x: scroll
}
.overflow-y-scroll {
    overflow-y: scroll
}
.whitespace-normal {
    white-space: normal
}
.whitespace-nowrap {
    white-space: nowrap
}
.rounded-sm {
    border-radius: 0.125rem
}
.rounded-lg {
    border-radius: 0.5rem
}
.rounded {
    border-radius: 0.25rem
}
.border {
    border-width: 1px
}
.border-0 {
    border-width: 0px
}
.border-2 {
    border-width: 2px
}
.border-solid {
    border-style: solid
}
.border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(74 85 104 / var(--tw-border-opacity))
}
.border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(45 55 72 / var(--tw-border-opacity))
}
.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}
.bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(45 55 72 / var(--tw-bg-opacity))
}
.bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 85 104 / var(--tw-bg-opacity))
}
.bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(26 32 44 / var(--tw-bg-opacity))
}
.bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(23 25 35 / var(--tw-bg-opacity))
}
.bg-unauthorized-mobile {
    background-image: url('/m-unauthorized.png')
}
.bg-cover {
    background-size: cover
}
.bg-no-repeat {
    background-repeat: no-repeat
}
.fill-current {
    fill: currentColor
}
.object-contain {
    -o-object-fit: contain;
       object-fit: contain
}
.p-7 {
    padding: 1.75rem
}
.p-5 {
    padding: 1.25rem
}
.p-3 {
    padding: 0.75rem
}
.p-0 {
    padding: 0px
}
.p-4 {
    padding: 1rem
}
.p-2 {
    padding: 0.5rem
}
.p-1\.5 {
    padding: 0.375rem
}
.p-1 {
    padding: 0.25rem
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}
.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
}
.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
}
.pt-5 {
    padding-top: 1.25rem
}
.pb-1 {
    padding-bottom: 0.25rem
}
.pt-4 {
    padding-top: 1rem
}
.pb-5 {
    padding-bottom: 1.25rem
}
.pr-9 {
    padding-right: 2.25rem
}
.pr-10 {
    padding-right: 2.5rem
}
.pr-5 {
    padding-right: 1.25rem
}
.pl-0 {
    padding-left: 0px
}
.pr-1 {
    padding-right: 0.25rem
}
.text-left {
    text-align: left
}
.text-center {
    text-align: center
}
.text-right {
    text-align: right
}
.align-middle {
    vertical-align: middle
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
}
.font-semibold {
    font-weight: 600
}
.font-bold {
    font-weight: 700
}
.font-medium {
    font-weight: 500
}
.font-normal {
    font-weight: 400
}
.uppercase {
    text-transform: uppercase
}
.italic {
    font-style: italic
}
.leading-5 {
    line-height: 1.25rem
}
.leading-normal {
    line-height: 1.5
}
.leading-6 {
    line-height: 1.5rem
}
.leading-10 {
    line-height: 2.5rem
}
.leading-8 {
    line-height: 2rem
}
.tracking-wider {
    letter-spacing: 0.05em
}
.tracking-wide {
    letter-spacing: 0.025em
}
.text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity))
}
.text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(45 117 214 / var(--tw-text-opacity))
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(113 128 150 / var(--tw-text-opacity))
}
.text-red-700 {
    --tw-text-opacity: 1;
    color: rgb(155 44 44 / var(--tw-text-opacity))
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(74 85 104 / var(--tw-text-opacity))
}
.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(56 161 105 / var(--tw-text-opacity))
}
.underline {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline
}
.no-underline {
    -webkit-text-decoration-line: none;
            text-decoration-line: none
}
.placeholder-gray-200::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(226 232 240 / var(--tw-placeholder-opacity))
}
.placeholder-gray-200:-ms-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(226 232 240 / var(--tw-placeholder-opacity))
}
.placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(226 232 240 / var(--tw-placeholder-opacity))
}
.opacity-40 {
    opacity: 0.4
}
.opacity-70 {
    opacity: 0.7
}
.opacity-100 {
    opacity: 1
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.outline {
    outline-style: solid
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.transition {
    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.checked\:border-transparent:checked {
    border-color: transparent
}
.checked\:bg-blue-600:checked {
    --tw-bg-opacity: 1;
    background-color: rgb(38 104 192 / var(--tw-bg-opacity))
}
.hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}
.hover\:font-bold:hover {
    font-weight: 700
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
}
@media (min-width: 320px) {
    .xs\:invisible {
        visibility: hidden
    }
    .xs\:my-2 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem
    }
    .xs\:ml-2 {
        margin-left: 0.5rem
    }
    .xs\:mr-2 {
        margin-right: 0.5rem
    }
    .xs\:mb-4 {
        margin-bottom: 1rem
    }
    .xs\:p-4 {
        padding: 1rem
    }
    .xs\:text-center {
        text-align: center
    }
    .xs\:text-xs {
        font-size: 0.75rem;
        line-height: 1rem
    }
    .xs\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}
@media (min-width: 640px) {
    .sm\:ml-5 {
        margin-left: 1.25rem
    }
    .sm\:ml-2 {
        margin-left: 0.5rem
    }
    .sm\:w-3\/5 {
        width: 60%
    }
    .sm\:w-4\/5 {
        width: 80%
    }
    .sm\:text-xs {
        font-size: 0.75rem;
        line-height: 1rem
    }
}
@media (min-width: 768px) {
    .md\:m-4 {
        margin: 1rem
    }
    .md\:mx-12 {
        margin-left: 3rem;
        margin-right: 3rem
    }
    .md\:my-3 {
        margin-top: 0.75rem;
        margin-bottom: 0.75rem
    }
    .md\:mt-0 {
        margin-top: 0px
    }
    .md\:ml-0 {
        margin-left: 0px
    }
    .md\:mt-9 {
        margin-top: 2.25rem
    }
    .md\:ml-7 {
        margin-left: 1.75rem
    }
    .md\:ml-4 {
        margin-left: 1rem
    }
    .md\:mb-6 {
        margin-bottom: 1.5rem
    }
    .md\:block {
        display: block
    }
    .md\:flex {
        display: flex
    }
    .md\:w-8\/12 {
        width: 66.666667%
    }
    .md\:w-1\/3 {
        width: 33.333333%
    }
    .md\:w-2\/3 {
        width: 66.666667%
    }
    .md\:w-1\/2 {
        width: 50%
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
    .md\:flex-row {
        flex-direction: row
    }
    .md\:bg-unauthorized {
        background-image: url('/unauthorized.png')
    }
    .md\:p-8 {
        padding: 2rem
    }
    .md\:pr-3 {
        padding-right: 0.75rem
    }
    .md\:text-left {
        text-align: left
    }
    .md\:text-xs {
        font-size: 0.75rem;
        line-height: 1rem
    }
    .md\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem
    }
}
@media (min-width: 1024px) {
    .lg\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }
    .lg\:my-auto {
        margin-top: auto;
        margin-bottom: auto
    }
    .lg\:mb-0 {
        margin-bottom: 0px
    }
    .lg\:ml-9 {
        margin-left: 2.25rem
    }
    .lg\:mr-10 {
        margin-right: 2.5rem
    }
    .lg\:ml-6 {
        margin-left: 1.5rem
    }
    .lg\:mr-8 {
        margin-right: 2rem
    }
    .lg\:flex {
        display: flex
    }
    .lg\:w-5\/6 {
        width: 83.333333%
    }
    .lg\:w-2\/6 {
        width: 33.333333%
    }
    .lg\:w-1\/2 {
        width: 50%
    }
    .lg\:w-1\/3 {
        width: 33.333333%
    }
    .lg\:w-2\/3 {
        width: 66.666667%
    }
    .lg\:w-2\/5 {
        width: 40%
    }
    .lg\:w-3\/5 {
        width: 60%
    }
    .lg\:grid-flow-col {
        grid-auto-flow: column
    }
    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }
    .lg\:flex-row {
        flex-direction: row
    }
    .lg\:border-r-0 {
        border-right-width: 0px
    }
    .lg\:border-l-0 {
        border-left-width: 0px
    }
    .lg\:pr-\[10\%\] {
        padding-right: 10%
    }
    .lg\:pl-\[10\%\] {
        padding-left: 10%
    }
    .lg\:text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem
    }
}
@media (min-width: 1280px) {
    .xl\:w-4\/5 {
        width: 80%
    }
}
@media (min-width: 1536px) {
    .\32xl\:w-3\/5 {
        width: 60%
    }
}

